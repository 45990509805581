import usePartySocket from 'partysocket/react';
import { useState, type RefObject } from 'react';
import { PartyToHostMessageSchema, type HostToPartyMessage, ROLE_HOST, type ActiveItem } from '../../party/types';
import type ReactPlayer from 'react-player';

const useStreamHost = (room: string, playerRef: RefObject<ReactPlayer>) => {
	const [playing, setPlaying] = useState(false);
	const [activeItem, setActiveItem] = useState<ActiveItem | null>(null);
	const socket = usePartySocket({
		host: 'sup-stream3.shahruz.partykit.dev',
		party: 'main',
		room,
		query: { role: ROLE_HOST },
		onMessage: async message => {
			try {
				const payload = PartyToHostMessageSchema.parse(JSON.parse(message.data));
				switch (payload.type) {
					case 'INIT':
						setActiveItem(payload.data.activeItem);
						break;
					case 'ACTIVE_ITEM':
						setActiveItem(payload.data);
						break;
					case 'PLAY':
						setPlaying(true);
						break;
					case 'PAUSE':
						setPlaying(false);
						break;
					case 'SEEK':
						playerRef.current?.seekTo(payload.data.seconds, 'seconds');
						break;
					case 'QUIT':
						break;
					case 'PLAY_NOW':
						const item = payload.data;
						if (item.type == 'audio') new Audio(item.url).play();
						break;
				}
			} catch (e) {
				console.error('Error parsing message:', e);
			}
		}
	});

	const sendMessage = (message: HostToPartyMessage) => socket.send(JSON.stringify(message));

	return { sendMessage, activeItem, playing };
};

export default useStreamHost;
